export const Environment = {
	PRODUCTION: true,
	CLIENT_ID: 'lusagua',
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.346.14',
	MODULES: [
		'asset-planning',
		'gap-analysis',
		'inspections'
	],
	TIMESTAMP: '2024-11-08T15:33:39.104Z',
	COMMIT: 'c07a6fdfcafec01a8036ee6759df63986798464c',
	BRANCH: 'task/AM-3210-Create-deploy-for-LUSAGUA',
	API_SERVER: 'https://lusagua-api.unoplatform.io:8080',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://lusagua-api.unoplatform.io:8082',
	FILE_CONVERTER_SERVER: 'https://lusagua-api.unoplatform.io:8081',
	PIPELINE_INTEGRITY_SERVER: null,
	DIGITAL_TWIN_SERVER: null
};
