import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgClass} from '@angular/common';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {WidgetsMap} from 'src/app/models/dashboards/widgets-map';
import {DashboardBarChartComponent} from 'src/app/components/dashboard/bar-chart/dashboard-bar-chart';
import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {DashboardLineChartComponent} from 'src/app/components/dashboard/line-chart/dashboard-line-chart.component';
import {DemoComponentsPage} from 'src/app/modules/demo/demo/components/demo-components.page';

export type ChartData = {
	// Labels to use on the chart.
	labels: string[],
	// Labels to apply on the chart.
	datasets: any[]
}

/**
 * The Dashboard widget component.
 */
@Component({
	selector: 'uno-widget',
	templateUrl: 'widget.component.html',
	styleUrls: ['widget.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass, DashboardBarChartComponent, DashboardLineChartComponent]
})
export class UnoWidgetComponent implements OnInit {
	/**
	 * The widget to show.
	 */
	@Input()
	public widget: DashboardWidget;

	public chartData: ChartData;

	public ngOnInit(): void {
		if (this.widget.data?.demo) {
			// Load dummy data.
			this.chartData = DemoComponentsPage.loadDemoData(this.widget);
			
		} else {
			if (this.widget.type in WidgetTypes) {
				const widgetData: DashboardWidgetInterface = WidgetsMap.get(this.widget.type);
				this.chartData = widgetData.getData();
			} else {
				console.error('EQS: Widget Type does not exist');
			}
		}
	}
}
